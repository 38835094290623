import { DeviceType } from '@aimmo-renew-home/shared/models/breakpoints.model';
import { Lang } from '@aimmo/i18n';

export interface EnvironmentConfig {
  phase: 'real' | 'beta' | 'qa' | 'dev' | 'local';
  production: boolean;
  ngForageName: string;
  apiUrl: string;
  sentry: {
    dsn: string;
    release: string;
    environment: string;
  };
  gtm: {
    id: string;
    debug: boolean;
  };
}

export const FORAGE_KEY_BANNER_HIDDEN_STATE = 'FORAGE_KEY_BANNER_HIDDEN_STATE';

export enum Pages {
  solutions = 'solutions',
  usecase = 'usecase',
  company = 'company',
  pricing = 'pricing',
  career = 'career',
}

export enum AimmoPaths {
  en = '/',
  ko = '/ko',
  enterprise = '/enterprise',
  admin = '/admin',
  adaas = '/adaas'
}

export const DESKTOP_WIDTH_BASE = 1440;
export const DESKTOP_SPACING_BASE = 120;

export const BREAK_POINTS = {
  '(max-width: 779px)': DeviceType.mobile,
  '(min-width: 780px) and (max-width: 1023px)': DeviceType.tablet,
  '(min-width: 1024px) and (max-width: 1439px)': DeviceType.desktop,
  '(min-width: 1440px)': DeviceType.fullSize
};

export const SUPPORT_LANGUAGES = [Lang.KO, Lang.EN];
export const DEFAULT_LANGUAGE = Lang.EN;
export const SAVED_LANGUAGE_KEY = 'RENEWAL_HOME_LANGUAGE';
export const HOME_MAIN_INTERVAL_DELAY_MS = 5000;
export const HOME_MAIN_SLIDE_INTERVAL_MS = 500;
export const AIMMO_HOME_SENTRY_DSN = 'https://bffbafdf209542f2840f3178228e4c8d@o219148.ingest.sentry.io/6679651';

export const AIMMO_PROD_GTM_ID = 'GTM-PGGSP9J';
export const AIMMO_BETA_GTM_ID = 'GTM-5CL6XJ9';
export const AIMMO_QA_GTM_ID = 'GTM-5CL6XJ9';
export const AIMMO_DEV_GTM_ID = 'GTM-5CL6XJ9';

export const DEV_AIMMO_HOME_EN_GOOGLE_CLIENT_ID = '550659931505-gpl9a1icl127h3j2j4dc44be2a9rjrjo.apps.googleusercontent.com';
export const PROD_AIMMO_HOME_EN_GOOGLE_CLIENT_ID = '550659931505-jprbr48i1u0r3b167u5e7c2cmv5b6ddh.apps.googleusercontent.com';

export const CONTACT_US_HREF_EN = 'https://contact.aimmo.ai?v=2';
export const CONTACT_US_HREF_KO = 'https://contactkr.aimmo.ai?v=2';

export const TALK_TO_SALES_HREF_EN = 'https://contact.aimmo.ai?v=2';
export const TALK_TO_SALES_HREF_KO = 'https://contactkr.aimmo.ai?v=2';

export const JOIN_US_HREF_EN = 'https://contact.aimmo.ai?v=2';
export const JOIN_US_HREF_KO = 'https://aimmo.wehire.kr/';

// 레거시 PRIVACY_POLICY 링크
// export const PRIVACY_POLICY_HREF_EN = 'https://www.notion.so/aimmo/Privacy-Policy-b9f295b9bf2a4fc3a50af84e62c49c29';
// export const PRIVACY_POLICY_HREF_KO = 'https://aimmo.notion.site/f0e0413eaf8f429cbd3fca087592a856';

export const PRIVACY_POLICY_HREF = 'https://www.aimmo.ai/privacy-and-terms';
export const ENTERPRISE_HREF = 'https://www.aimmo.ai/enterprise';
export const HOMEPAGE_HREF = 'https://www.aimmo.ai';

export const TERM_OF_USE_HREF_EN = 'https://www.notion.so/aimmo/Terms-of-Use-3c48959f808a4db599b69000f4c50da8';
export const TERM_OF_USE_HREF_KO = 'https://aimmo.notion.site/46abf09e11d14e16a97848f7b1754a82';

export const DEFAULT_TITLE = 'AI Data Company';
export const SITE_NAME = 'AIMMO';
export const SITE_URL = 'https://aimmo.ai/en';
export const SITE_IMAGE = 'https://static-aimmo-home.web.app/aimmo_og_image.png';
export const OG_TITLE = 'AIMMO | AI Data Company';
export const SITE_DESC = 'Enabling a data-powered tomorrow, AI Data company AIMMO';
export const BANNER_LINK = 'https://gongu.copyright.or.kr/gongu/main/main.do';
export const LABELERS_URL = 'https://labelers.aimmo.ai/';
export const PROJECT_MERGE_NOTION_LINK = 'https://www.notion.so/aimmo/Policy-Project-Merge-60841343e0a243bcbf229d670f280d27?pvs=4';
export const PROJECT_MERGE_GUIDE_LINK_EN = 'https://bit.ly/mergeprojects_en';
export const RESOURCE_URL_BASE = 'https://static-aimmo-home.web.app';
export const RESOURCE_CUSTOMERS_URL = `${RESOURCE_URL_BASE}/customers`;
export const RESOURCE_CORE_URL = `${RESOURCE_URL_BASE}/core`;
export const RESOURCE_MAIN_SOLUTIONS_URL = `${RESOURCE_URL_BASE}/solutions`;
export const RESOURCE_DAAS_URL = `${RESOURCE_URL_BASE}/solutions/daas`;
export const RESOURCE_STUDIO_URL = `${RESOURCE_URL_BASE}/solutions/studio`;
export const RESOURCE_USE_CASE_URL = `${RESOURCE_URL_BASE}/usecase`;
export const RESOURCE_CAREER_URL = `${RESOURCE_URL_BASE}/career`;
export const RESOURCE_DATA_VOUCHER_URL = `${RESOURCE_URL_BASE}/data-voucher`;

export const MainFiles = {
  intro: {
    dataCollectionPoster: 'main-dpp-01.jpg',
    dataCollection: 'main-dpp-01.mp4',
    dataCurationPoster: 'main-dpp-02.jpg',
    dataCuration: 'main-dpp-02.mp4',
    dataAnnotationPoster: 'main-dpp-03.jpg',
    dataAnnotation: 'main-dpp-03.mp4',
    dataEvaluationPoster: 'main-dpp-04.jpg',
    dataEvaluation: 'main-dpp-04.mp4',
  },
  solutions: {
    adDaaS: {
      poster: 'solution-ad-daas.jpg',
      video: 'solution-ad-daas.mp4'
    },
    scDaaS: 'solution-sc-daas.jpg',
    rtDaaS: {
      poster: 'solution-rt-daas.jpg',
      video: 'solution-rt-daas.mp4'
    },
    studio: 'solution-studio.jpg',
    smartLabeling: {
      poster: 'solution-smart-labeling.jpg',
      video: 'solution-smart-labeling.mp4'
    },
  },
  aimmoMap: 'aimmo-map.png',
  aimmoMapMobile: 'aimmo-map-mobile.png'
} as const;

export const CoreFiles = {
  intro: 'core-intro.svg',
  introMobile: 'core-intro-mobile.svg',
  dataCollection: {
    poster: 'core-data-collection.png',
    video: 'core-data-collection.mp4'
  },
  dataCollectionMobile: {
    poster: 'core-data-collection-mobile.jpg',
    video: 'core-data-collection-mobile.mp4'
  },
  dataCuration: {
    poster: 'core-data-curation.png',
    video: 'core-data-curation.mp4'
  },
  dataAnnotation: {
    poster: 'core-data-annotation.jpg',
    video: 'core-data-annotation.mp4'
  },
  dataAnnotationMobile: {
    poster: 'core-data-annotation-mobile.jpg',
    video: 'core-data-annotation-mobile.mp4'
  },
  dataEvaluationBefore: 'core-data-evaluation-before.png',
  dataEvaluationAfter: 'core-data-evaluation-after.png'
} as const;

export const DaaSFiles = {
  intro: {
    poster: 'daas-intro.png',
    video: 'daas-intro.mp4',
  },
  whyDaaS: 'why-daas.png',
  whyDaaSMobile: 'why-daas-mobile.png',
  adDaaS: 'daas-case-01.jpg',
  adDaaSMobile: 'daas-case-01-mobile.jpg',
  scDaaS: 'daas-case-02.jpg',
  scDaaSMobile: 'daas-case-02-mobile.jpg',
  rtDaaS: 'daas-case-03.jpg',
  rtDaaSMobile: 'daas-case-03-mobile.jpg',
} as const;

export const StudioFiles = {
  features: {
    dashboard: 'studio-realtime-dashboard.png',
    feedback: 'studio-visual-feedback.png',
    linkShare: 'studio-link-share.png',
    analysis: 'studio-accuracy-analysis.png',
    monitoring: 'studio-worker-monitoring.png',
    communication: 'studio-realtime-communication.png'
  },
  smartLabeling: {
    [Lang.KO]: {
      poster: 'studio-smart-labeling.jpg',
      video: 'studio-smart-labeling.mp4'
    },
    [Lang.EN]: {
      poster: 'studio-smart-labeling-en.png',
      video: 'studio-smart-labeling-en.mp4'
    },
  }
} as const;

export const CareerFiles = {
  slogan: [
    { x1: 'career-slogan-01.jpg', x2: 'career-slogan-01@2x.jpg' },
    { x1: 'career-slogan-02.jpg', x2: 'career-slogan-02@2x.jpg' },
    { x1: 'career-slogan-03.jpg', x2: 'career-slogan-03@2x.jpg' }
  ],
  coreValues: {
    initiatives: { x1: 'career-core-value-01.jpg', x2: 'career-core-value-01@2x.jpg' },
    learn: { x1: 'career-core-value-02.jpg', x2: 'career-core-value-02@2x.jpg' },
    teamwork: { x1: 'career-core-value-03.jpg', x2: 'career-core-value-03@2x.jpg' },
    excelDeliver: { x1: 'career-core-value-04.jpg', x2: 'career-core-value-04@2x.jpg' },
    creatingBetterWorld: { x1: 'career-core-value-05.jpg', x2: 'career-core-value-05@2x.jpg' }
  }
} as const;

export const DataVoucherFiles = {
  process: 'data-voucher-process.png',
  organization: {
    kdata: 'data-voucher-organization-kdata.png',
    msit: 'data-voucher-organization-msit.png',
    nipa: 'data-voucher-organization-nipa.png',
    aimmo: 'data-voucher-organization-aimmo.png',
  },
  intro: {
    organizations: 'data-voucher-organizations.png',
    cards: [
      'data-voucher-intro-card-01.png',
      'data-voucher-intro-card-02.png',
      'data-voucher-intro-card-03.png'
    ]
  },
  awards: [
    'data-voucher-award-card-01.png',
    'data-voucher-award-card-02.png',
    'data-voucher-award-card-03.png',
    'data-voucher-award-card-04.png'
  ],
  aiVoucherBusiness: {
    desktop: 'ai-voucher-business.png',
    mobile: 'ai-voucher-business-mobile.png',
  },
  dataVoucherBusiness: {
    desktop: 'data-voucher-business.png',
    mobile: 'data-voucher-business-mobile.png',
  },
  consulting: [
    'data-voucher-consulting-01.png',
    'data-voucher-consulting-02.png',
    'data-voucher-consulting-03.png',
    'data-voucher-consulting-04.png'
  ]
} as const;

export const META_TAGS = [
  { name: 'apple-mobile-web-app-capable', content: 'yes' },
  {
    name: 'keywords',
    content: 'AI, artificial intelligence, machine learning, ai software, ai companies, lidar, autonomous driving, autonomous cars, autonomous vehicles, labelling, data labeling, data annotation'
  },
  { name: 'description', content: SITE_DESC },
  { property: 'og:type', content: 'website' },
  { property: 'og:site_name', content: SITE_NAME },
  { property: 'og:title', content: OG_TITLE },
  { property: 'og:description', content: SITE_DESC },
  { property: 'og:image', content: SITE_IMAGE },
  { property: 'og:url', content: SITE_URL },
  { name: 'format-detection', content: 'telephone=no, address=no, email=no' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  { charset: 'utf-8' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: SITE_NAME },
  { name: 'twitter:title', content: SITE_NAME + ' | ' + DEFAULT_TITLE },
  { name: 'twitter:description', content: SITE_DESC },
  { property: 'twitter:image', content: SITE_IMAGE },
  { name: 'naver-site-verification', content: 'db564f77aa05fe4d399cfe79310721555e28255d' }
];

export const ContactUsDestination = {
  [Lang.EN]: CONTACT_US_HREF_EN,
  [Lang.KO]: CONTACT_US_HREF_KO
} as const;

export const TalkToSalesDestination = {
  [Lang.EN]: TALK_TO_SALES_HREF_EN,
  [Lang.KO]: TALK_TO_SALES_HREF_KO
} as const;

export const JoinUsDestination = {
  [Lang.EN]: JOIN_US_HREF_EN,
  [Lang.KO]: JOIN_US_HREF_KO
} as const;
